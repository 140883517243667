import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getResearchs } from "../services/getResearch";
import imgsrc1 from '../research1.jpg';
import imgsrc2 from '../research2.jpg';
import imgsrc3 from '../research3.jpg';

const Newsletter = () => {
  let x=0;
  const navigate = useNavigate();
  const [researchData, setResearchData] = useState([]);
  

  useEffect(() => {
    let mounted = true;
    
    getResearchs().then((data) => {
      if (mounted) {
        setResearchData(data);
        
       
      }
    });
    return () => (mounted = false);
  }, []);


  return (
    <div dir="rtl" className="cards-reasearch flex flex-col items-center justify-center gap-0">
      <div className="container-card gap-6 items-center h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:flex px-12 pt-28  w-full py-16 bg-gray-100 px-4">
        <div className="titles-info">جدیدترین تحقیقات</div>
   

{researchData.slice(0, 3).map((item, index) => (
  <div key={index} className="card_1 shadow-lg card">
    <img className="w-40 object-cover" src={item.rImage} alt="" />

    
    <span className="text-gray-600 p-3">{item.rTitle}</span>
    <h1 className="text-clip font-bold px-3 pb-1 mb-2">{item.title}</h1>
    <div style={{marginRight: 6 + 'px',marginLeft: 6 + 'px'}}
      dangerouslySetInnerHTML={{ __html: item.rBody.substring(0,70) }}
    /><span style={{marginRight: 6 + 'px',marginLeft: 6 + 'px'}}>...</span>
    
    
    <Link
      className="text-blue-500 px-3 pb-6"
      onClick={(e) => {
        e.preventDefault();
       
        navigate('/description', { state: item });
        window.scrollTo(0, 0);
      }}
    >
      معلومات بیشتر
    </Link>
  </div>
))}
        </div>
      
      <Link
     className="mx-auto relative inline-flex items-center justify-center p-0.5  mt-4 mb-12 overflow-hidden text-sm font-medium text-gray-100 rounded-lg group bg-[000f3f] from-[#002a3f] to-blue-500 group-hover:from-purple-600 group-hover:to-blue-[#002a3f] hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-[#002a3f] dark:focus:ring-[#002a3f]"
      onClick={(e) => {
        e.preventDefault();
       
        navigate('/reportNews')
        window.scrollTo(0, 0);
      }}
    >
       <span dir="rtl" className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-[#002a3f] rounded-md group-hover:bg-[#001a3f]">
          مشاهده تحقیقات بیشتر...
        </span>
    </Link>
      
    </div>
  );
};

export default Newsletter;
