import React from 'react';

import Hero from '../components/Hero';
import Newsletter from '../components/Newsletter';
import { getResearchs } from '../services/getResearch';
import  { useEffect, useState } from 'react';

import cctvImage from "../assets/cctv-security-technology-with-lock-icon-digital-remix_53876-104935.jpg";


import YouTubeEmbed  from '../components/Video'
import { getVideos } from '../services/getVideos';



const Home = () => {

  const [research, setResearch] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  

  
    useEffect(() => {
     let mounted = true;
     getResearchs()
       .then(data => {
         if(mounted) {
           setResearch(data);
           setLoading(false);
         }
       })
     return () => mounted = false;
   }, [])




   const currentRecords = research.slice(1, 
    3);



  return (
    
    <div className='bg-white'>
      <Hero />
      <YouTubeEmbed />
      <Newsletter />
      <div dir="rtl" className="cctv p-12 flex flex-col gap-4 items-center">
        <div className="cctv-container mt-16 flex flex-col md:flex-row gap-6 sm:gap-8 ">
          <img src={cctvImage} className="cctv-image object-cover h-auto sm:h-50 w-full md:w-1/2 rounded-lg shadow-lg" alt="CCTV Services" />
          <section className="flex flex-col text-center  md:text-right md:w-1/2">
            <h2 className="text-4xl mb-10 text-[#001F3F] font-semibold">خدمات نوین سی سی تی وی</h2>
            <h3 className="text-lg text-gray-700">خدمات جدیدترین سیستم های سی سی تی وی با کیفیت جهانی اکنون در قندهار قابل دسترس است. برای دریافت این خدمات با شرکت تماس بگیرید</h3>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Home;
