import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getVideos } from '../services/getVideos';

const YouTubeEmbed = () => {
  
  const navigate =useNavigate()
  return (

    <div className='grid  p-4 py-12 md:grid-rows-1  z mt-8'>


{/* 

    
    
    <div dir='rtl' className='md:col-span-2  mt-1 pr-28 md:mt-8'>
      
      <h1 className='text-2xl text-black'>کانادا ۳۱۰ پناهجوی افغانستان را از پاکستان انتقال داد</h1>
      <br />
      <p className='text-white'>در ادامه انتقال پناهجویان افغانستانی از پاکستان به کانادا، دولت این کشور اعلام کرد که ۳۱۰ پناهجوی اهل افغانستان را در یک پرواز چارتر</p>

    

      </div> */}


   <div className='md:col-span-2 flex flex-col justify-center items-center rounded-xl '>
      
    
      <iframe className='vedio md:scale-125  shadow-md  md:m-6 rounded-lg '
        width='700'
        height='370'
        
        src={`https://www.youtube.com/embed/Y_kZ5LQrZGQ`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true"
        
      > </iframe>
        

<Link
               class="mx-auto relative inline-flex items-center justify-center p-0.5 mb-2 mt-16 overflow-hidden text-sm font-medium text-gray-100 rounded-lg group bg-[000f3f] from-[#002a3f] to-blue-500 group-hover:from-purple-600 group-hover:to-blue-[#002a3f] hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-[#002a3f] dark:focus:ring-[#002a3f]"
              onClick={(e) => {
                e.preventDefault();
                navigate('/videos');
                window.scrollTo(0, 0);
              }}
            >
              <span dir="rtl" class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-[#002a3f] rounded-md group-hover:bg-[#001a3f]">
مشاهده ویدیوهای بیشتر...
</span>
            </Link>


     
    </div>

    </div>
    
  );
};

export default YouTubeEmbed;