import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const navItems = [
    { path: "/", label: "صفحه نخست" },
  
    { path: "/services", label: "خدمات" },
    { path: "/activities", label: "فعالیت ها" },
    { path: "/videos", label: "ویدیو ها" },
    { path: "/tafser", label: "تفسیر " },
    { path: "/about", label: "درباره ما" },
  ];

  const MobileMenu = () => (
    <div className={`md:hidden ${isMenuOpen ? "fixed inset-0 z-50 bg-black bg-opacity-80" : "hidden"}`}>
      <div className="flex justify-end p-4">
        <button onClick={toggleMenu}>
          <FaTimes className="w-5 h-5 text-white" />
        </button>
      </div>
      <ul className="flex flex-col items-center space-y-4 mt-20">
        {navItems.map(({ path, label }) => (
          <li
            key={path}
            className="text-white hover:text-gray-200 text-lg font-bold cursor-pointer"
          >
            <NavLink
              to={path}
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive
                  ? "text-slate-600 px-4 py-2 rounded-full bg-slate-200  text-xl font-bold text-white"
                  : "text-white hover:text-gray-200"
              }
            >
              {label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <header className="bg-slate-100 font-vazir">
      <nav className="mx-auto py-4 max-w-full h-[13vh] flex p-8 justify-between items-center">
        <div className="flex items-center">
          <NavLink to="/" className="flex items-center justify-center">
            <img className="w-20 h-20 rounded-full border-2  hover:border-gray-400 transition duration-300" src={logo} alt="Logo" />
          </NavLink>
        </div>

        <ul dir="rtl" className="hidden md:flex gap-4 lg:gap-11 lg:text-lg justify-start">
          {navItems.map(({ path, label }) => (
            <li
              key={path}
              className={({ isActive }) =>
                `text-slate-600 px-4 py-2 rounded-full hover:bg-slate-200 lg:flex lg:flex-shrink-0 text-xl font-bold text-white ${
                  isActive ? "bg-slate-200 font-bold" : ""
                }`
              }
            >
              <NavLink
                to={path}
                className={({ isActive }) =>
                  isActive
                    ? " text-slate-600 px-4 py-2 rounded-full bg-slate-200  text-xl font-bold text-white"
                    : "text-slate-600 hover:text-slate-800"
                }
              >
                {label}
              </NavLink>
            </li>
          ))}
        </ul>

        <MobileMenu />
        <div className="md:hidden">
          <button onClick={toggleMenu} className="cursor-pointer">
            {isMenuOpen ? (
              ""
            ) : (
              <FaBars className="w-5 h-5" />
            )}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Nav;