import React, { useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useTable, useSortBy, usePagination } from 'react-table';
import { getTafsir } from "../services/gettafsir";
import { Link } from 'react-router-dom';

const FileDownloadPage = () => {
  const [files, setFiles] = useState([]);


  useEffect(() => {
    let mounted = true;

    getTafsir().then((data) => {
      if (mounted) {
        setFiles(data);
        
      }
    });
    return () => (mounted = false);
  }, []);


  

  const columns = React.useMemo(
    () => [
      {
        Header: 'نمبر',
        accessor: (row, index) => index + 1,
      },
      {
        Header: 'توضیحات',
        accessor: 'tDesc',
      },
      {
        Header: 'دانلود',
        accessor: 'tFile',
        Cell: (props) => (
          <a className='hover:text-blue-800' href={props.value}>
            <FaDownload />
          </a>
        ),
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: files,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const handleDownload = (fileId) => {
    console.log(`Downloading file with ID: ${fileId}`);
  };


  return (
    <div dir='rtl' className="container px-16 mx-auto my-8">
      <h1 className="text-3xl mt-16 font-bold mb-8 text-[#00243f] text-center">تفسیر قرآن (برگرفته از سخنان نعمان علی خان)</h1>
      <table
        {...getTableProps()}
        className="w-full table-auto bg-white shadow-md rounded-md"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-2 bg-gray-200 text-[#00243f] font-bold "
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody  {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-4 py-2 text-[#00243f] text-center"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-4 flex justify-between items-center">
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <div>
       
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="px-4 py-2 bg-gray-300 rounded-md mr-2"
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="px-4 py-2 bg-gray-300 rounded-md mr-2"
          >
            {'>'}
          </button>
        
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="ml-4 border rounded-md bg-[#001f3f] text-white p-1"
          >
            {[5, 10, 20].map((size) => (
              <option key={size} value={size}>

                
                نشان دادن  
                
                <pre >  {size}  </pre> 
                 فایل ها
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FileDownloadPage;