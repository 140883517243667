import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';




const DetailsPageActivities = () => {
  const { state } = useLocation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 100) {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % state.images.length);
          return 0;
        }
        return newProgress;
      });
    }, 30);

    return () => clearInterval(intervalId);
  }, [state.images.length]);

  return (
    <div dir="rtl" className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-7xl mx-auto">
        <div className="rounded-lg overflow-hidden relative shadow-lg">
          <img
            src={state.images[currentImageIndex].images}
            alt={state.title}
            className="w-full h-auto md:h-[70vh] object-cover"
          />

         
        </div>

        <div className="flex pr-6 gap-x-4 mt-6">
          {state.images.map((image, index) => (
            <div
              key={index}
              className={` circular-image relative top-[-130px] bottom-80 md:bottom-80 w-14 h-14 rounded-full  border-2 ${
                index === currentImageIndex ? 'border-transparent' : 'border-gray-300'
              }`}
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
            >
              <svg className="absolute inset-0 z-20 w-16 h-16">
                {index === currentImageIndex && (
                  <circle
                    r="28"
                    cx="30"
                    cy="30"
                    style={{
                      stroke: '#001f3f',
                      strokeWidth: '2',
                      strokeDasharray: '280.71573344772112',
                      strokeDashoffset: progress === 100 ? 0 : 400.71573344772112 - (progress / 120) * 400.71573344772112,
                      fill: 'transparent',
                      transformOrigin: '8% 0%',
                      transform: 'translate(12%, -4%)',
                    }}
                  />
                )}
              </svg>
              <figure className="absolute inset-0">
                <img
                  src={image.images}
                  alt={`Image ${index + 1}`}
                  className="w-full h-full object-cover transition-opacity duration-200"
                  style={{
                    opacity: index === currentImageIndex ? 1 : 0.5,
                    borderRadius: '50%',
                  }}
                />
              </figure>
            </div>
          ))}
        </div>
         <div className="pb-6">
            <h2 className="text-3xl font-bold text-[#001F3F] mb-4">{state.activityTitle}</h2>
         

           
          </div>
        <div 
      dangerouslySetInnerHTML={{ __html:state.activityBody }}
    />
      </div>  
    </div>
  );
};

export default DetailsPageActivities;