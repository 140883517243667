import React from 'react';
import { useLocation } from 'react-router-dom';

function Description() {
  const { state } = useLocation();

  console.log("The date is:",state.created_at)
  return (
    <div dir='rtl'  className="container  mx-auto w-fit py-8 px-4 sm:px-6 lg:px-8  bg-[#ffffff]">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="flex flex-col justify-center items-center p-10">
          <div className="md:w-1/2">
            <img
              className="w-full h-64 object-cover md:h-auto rounded-lg"
              src={state.rImage}
              alt=""
            />
          </div>
          <div className="p-6 md:w-1/2">
            <div className="flex items-center text-sm mb-2">
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                />
              </svg>
              <span>{state.created_at.substring(0,10)}</span>
            </div>
            <h1 className="text-2xl font-bold mb-4 text-[#001F3F]">{state.rTitle}</h1>

            <div 
      dangerouslySetInnerHTML={{ __html:state.rBody }}
    />


            <p className="text-gray-800 mb-6">{}</p>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;