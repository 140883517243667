import React, { useRef, useState } from "react";
import logo from "../assets/logo.png";
import emailjs from "@emailjs/browser";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Register = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_lobt666", "template_wnv3o15", form.current, {
        publicKey: "O5y1SP2ZlaSMF6KjF",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  // let history = useHistory();

  const [Name, setName] = useState(null);
  const [FName, setFName] = useState(null);
  const [Prevince, setPrevince] = useState(null);
  const [PN, setPN] = useState(null);
  const [Email, setEmail] = useState(null);
  const [Ubranch, setUbranch] = useState(null);
  const [Semister, setSemister] = useState(null);
  const [Wtime, setWtime] = useState(null);
  const [Wfield, setWfield] = useState(null);
  const [Eng_r, setEng_r] = useState(null);
  const [Eng_w, setEng_w] = useState(null);
  const [Eng_s, setEng_s] = useState(null);
  const [Other_Lang, setOther_Lang] = useState(null);
  const [Other_Lang_r, setOther_Lang_r] = useState(null);
  const [Other_Lang_w, setOther_Lang_w] = useState(null);
  const [Other_Lang_s, setOther_Lang_s] = useState(null);
  const [Computer_apps, setComputer_apps] = useState(null);
  const [Iq1, setIq1] = useState(null);
  const [Iq2, setIq2] = useState(null);
  const [Iq3, setIq3] = useState(null);
  const [Iq4, setIq4] = useState(null);
  const [Iq5, setIq5] = useState(null);
  const [Iq6, setIq6] = useState(null);
  const [Iq7, setIq7] = useState(null);
  const [Iq8, setIq8] = useState(null);
  const [Iq9, setIq9] = useState(null);
  const [Iq10, setIq10] = useState(null);
  const [Iq11, setIq11] = useState(null);
  const [Iq12, setIq12] = useState(null);
  const [Iq13, setIq13] = useState(null);
  const [Iq14, setIq14] = useState(null);
  const [Iq15, setIq15] = useState(null);
  const [Iq16, setIq16] = useState(null);
  const [Iq17, setIq17] = useState(null);
  const [Iq18, setIq18] = useState(null);
  const [Iq19, setIq19] = useState(null);

  const addNewApplicant = async () => {
    let formField = new FormData();
    formField.append("Name", Name);
    formField.append("FName", FName);
    formField.append("Prevince", Prevince);
    formField.append("PN", PN);
    formField.append("Email", Email);
    formField.append("Ubranch", Ubranch);
    formField.append("Semister", Semister);
    formField.append("Wtime", Wtime);
    formField.append("Wfield", Wfield);
    formField.append("Eng_r", Eng_r);
    formField.append("Eng_w", Eng_w);
    formField.append("Eng_s", Eng_s);
    formField.append("Other_Lang", Other_Lang);
    formField.append("Other_Lang_r", Other_Lang_r);
    formField.append("Other_Lang_w", Other_Lang_w);
    formField.append("Other_Lang_s", Other_Lang_s);
    formField.append("Computer_apps", Computer_apps);
    formField.append("Iq1", Iq1);
    formField.append("Iq2", Iq2);
    formField.append("Iq3", Iq3);
    formField.append("Iq4", Iq4);
    formField.append("Iq5", Iq5);
    formField.append("Iq6", Iq6);
    formField.append("Iq7", Iq7);
    formField.append("Iq8", Iq8);
    formField.append("Iq9", Iq9);
    formField.append("Iq10", Iq10);
    formField.append("Iq11", Iq11);
    formField.append("Iq12", Iq12);
    formField.append("Iq13", Iq13);
    formField.append("Iq14", Iq14);
    formField.append("Iq15", Iq15);
    formField.append("Iq16", Iq16);
    formField.append("Iq17", Iq17);
    formField.append("Iq18", Iq18);
    formField.append("Iq19", Iq19);

    await axios({
      method: "post",
      url: "http://localhost:8000/api/createApplicant/",
      data: formField,
    }).then((response) => {
      console.log(response.data);
      // history.push("/");
    });
  };

  return (
    <div
    style={{
      background:
        "linear-gradient(to right, #002f3f, #002a3f)",
    }} 
    className="font-vazir flex w-full justify-center items-center  text-center bg-sky-500 ">
      <section className="h-full  ">
        <div className="container h-full p-10">
          <div className=" flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
            <div className="w-full">
              <div className="block rounded-lg bg-white shadow-lg dark:bg-sky-700 bg-opacity-">
                <div className="g-0 lg:flex lg:flex-wrap">
                  {/* <!-- right column container--> */}
                  <div className="text-center justify-center md:px-0 w-full">
                    <div className="m-12 md:mx-36 md:p-12">
                      {/* <!--Logo--> */}
                      <div className="text-center pt-3">
                        <img
                          className="mx-auto  w-24 md:w-36 bg-stone-50 rounded-full"
                          src={logo}
                          alt="logo"
                        />
                        <h4
                          dir="rtl"
                          className=" mt-1 pb-1 text-xl font-semibold"
                        >
                          شرکت فرهنگی رسانه ای لوی لالا
                        </h4>
                        <br />
                        <h4 className="mb-12 text-lg">
                          فورم ثبت نام کارآموزها
                        </h4>
                      </div>

                      <form
                        ref={form}
                        className="text-center flex flex-col justify-center items-center "
                        onSubmit={sendEmail}
                      >
                        <div className="">
                          <div className="text-center items-center justify-center flex flex-col">
                            <h1 className="text-4xl mb-6 color-[#002f3f]">معلومات شخصی</h1>
                            <div
  dir="rtl"
  id="personal-info"
  className="   m-4 w-full text-white p-8 rounded-3xl grid grid-cols-1 md:grid-cols-3 gap-6 text-center"
>
  <label className="text-center ">
    نام
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      name="Name"
      value={Name}
      onChange={(e) => setName(e.target.value)}
      type="text"
    />
  </label>

  <label className="text-center ">
    نام والد
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      type="text"
      name="FName"
      value={FName}
      onChange={(e) => setFName(e.target.value)}
    />
  </label>

  <label className="text-center ">
    ولایت
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      type="text"
      name="Prevince"
      value={Prevince}
      onChange={(e) => setPrevince(e.target.value)}
    />
  </label>

  <label className="text-center ">
    شماره تلیفون
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      type="number"
      name="PN"
      value={PN}
      onChange={(e) => setPN(e.target.value)}
    />
  </label>

  <label className="text-center ">
    ایمیل آدرس
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      type="email"
      name="Email"
      value={Email}
      onChange={(e) => setEmail(e.target.value)}
    />
  </label>

  <label className="text-center ">
    رشته تحصیلی
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      type="text"
      name="Ubranch"
      value={Ubranch}
      onChange={(e) => setUbranch(e.target.value)}
    />
  </label>

  <label className="text-center ">
    سمستر تحصیلی
    <br />
    <input
      className="mr-2 px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      type="number"
      name="Semister"
      value={Semister}
      onChange={(e) => setSemister(e.target.value)}
    />
  </label>

  <label>
    اوقات کاری <br />
    <select
      className="mr-2  text-[#001a3f] px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      name="Wtime"
      value={Wtime}
      onChange={(e) => setWtime(e.target.value)}
    >
      <option value="option1">قبل از ظهر</option>
      <option value="option2">بعد از ظهر</option>
    </select>
  </label>

  <label>
    در کدام بخش کار می کنید؟
    <br />
    <select
      className="mr-2 px-4 py-2 text-[#001a3f] rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
      name="Wfield"
      value={Wfield}
      onChange={(e) => setWfield(e.target.value)}
    >
      <option value="Translation">ترجمه</option>
      <option value="Graphics">گرافیک</option>
      <option value="Research">تحقیق</option>
      <option value="Web App Design">ویب آپ دیزاین</option>
      <option value="Admin">ادمین</option>
    </select>
  </label>
</div>
                            <h1 className="text-2xl">
                              آشنایی به زبانهای خارجی، کامپیوتر و سایر دوره ها
                            </h1>








                            <div
                              dir="rtl"
                              className="m-4 bg-slate-800 text-slate-400 p-10 rounded-3xl grid grid-cols-1  md:grid-cols-1 gap-14 text-center items-center justify-center"
                            >
                              {/* first style for the  */}





                              <div class="text-center flex flex-col">
  <p class="mt-2 mb-3 text-2xl">انگلیسی</p>
  <br />
  <p class="mb-2 text-xl">خواندن</p>
  <div class="flex flex-col  gap-4">
    <label>
      ضعیف
      <input
        type="radio"
        checked={true}
        name="Eng_r"
        value={Eng_r}
        onChange={(e) => setEng_r('ضعیف')}
        class="mr-1"
      />
    </label>

    <label>
      متوسط
      <input
        type="radio"
        name="Eng_r"
        value={Eng_r}
        onChange={(e) => setEng_r('متوسط')}
        class="mr-1"
      />
    </label>

    <label>
      خوب
      <input
        type="radio"
        name="Eng_r"
        value={Eng_r}
        onChange={(e) => setEng_r('خوب')}
        class="mr-1"
      />
    </label>

    <label>
      عالی
      <input
        type="radio"
        name="Eng_r"
        value={Eng_r}
        onChange={(e) => setEng_r('عالی')}
        class="mr-1"
      />
    </label>
  </div>

  <p class="mb-2 text-xl">نوشتن</p>
  <div class="flex gap-4">
    <label>
      ضعیف
      <input
        type="radio"
        name="Eng_w"
        value={Eng_w}
        onChange={(e) => setEng_w('ضعیف')}
        class="mr-1"
      />
    </label>

    <label>
      متوسط
      <input
        type="radio"
        name="Eng_w"
        value={Eng_w}
        onChange={(e) => setEng_w('متوسط')}
        class="mr-1"
      />
    </label>

    <label>
      خوب
      <input
        type="radio"
        name="Eng_w"
        value={Eng_w}
        onChange={(e) => setEng_w('خوب')}
        class="mr-1"
      />
    </label>

    <label>
      عالی
      <input
        type="radio"
        name="Eng_w"
        value={Eng_w}
        onChange={(e) => setEng_w('عالی')}
        class="mr-1"
      />
    </label>
  </div>

  <p class="mb-2 text-xl">مکالمه</p>
  <div class="flex gap-4">
    <label>
      ضعیف
      <input
        type="radio"
        name="Eng_s"
        value={Eng_s}
        onChange={(e) => setEng_s('ضعیف')}
        checked={true}
        class="mr-1"
      />
    </label>

    <label>
      متوسط
      <input
        type="radio"
        name="Eng_s"
        value={Eng_s}
        onChange={(e) => setEng_s('متوسط')}
        class="mr-1"
      />
    </label>

    <label>
      خوب
      <input
        type="radio"
        name="Eng_s"
        value={Eng_s}
        onChange={(e) => setEng_s('خوب')}
        class="mr-1"
      />
    </label>

    <label>
      عالی
      <input
        type="radio"
        name="Eng_s"
        value={Eng_s}
        onChange={(e) => setEng_s('عالی')}
        class="mr-1"
      />
    </label>
  </div>
</div>
{/* first part to adding tailwaind */}
                            

<div class="text-center">
  <p class="mt-2 mb-3 text-2xl">آشنایی با کامپیوتر</p>
  <br />
  <div class="flex flex-col gap-4">
    <label class="flex items-center">
      <input
        name="Windows"
        type="checkbox"
        value="option1"
        class="mr-2"
      />
      <span>Windows</span>
    </label>

    <label class="flex items-center">
      <input
        name="word"
        type="checkbox"
        value="option1"
        class="mr-2"
      />
      <span>MS Word</span>
    </label>

    <label class="flex items-center">
      <input
        name="excel"
        type="checkbox"
        value="option1"
        class="mr-2"
      />
      <span>MS Excel</span>
    </label>

    <label class="flex items-center">
      <input
        name="ppt"
        type="checkbox"
        value="option1"
        class="mr-2"
      />
      <span>MS Power Point</span>
    </label>

    <label class="flex items-center">
      <input
        name="internet"
        type="checkbox"
        value="option1"
        class="mr-2"
      />
      <span>Internet</span>
    </label>
  </div>
</div>















                            </div>
                          </div>








        

        <div
          dir="rtl"
          className="text-center  md:scale-100 flex flex-col text-black text-lg"
        >
          <label className="m-5 sm:scale-[68%] md:scale-100">
            چرا می خواهید در این شرکت کار کنید و انگیزه شما
            چیست؟ <br />
            <br />
            <textarea
              className="p-3 bg-slate-50 text-black"
              name=""
              cols="70"
              rows="10"
            ></textarea>
          </label>

          <label className="m-5 sm:scale-[68%] md:scale-100">
            در چه کار قوی هستید؟
            <br />
            <br />
            <textarea
              className="p-3 bg-slate-50 text-black"
              name=""
              cols="70"
              rows="5"
            ></textarea>
          </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              در مورد شرکت لوی لالا چی اطلاعاتی دارید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="6"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              چرا فکر می کنید که ما باید شمارا استخدام کنیم؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="5"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              هدف شما از کار در این شرکت چه است و آینده خود را
                              در این شرکت چطور می بینید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="10"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              بزرگترین دستاورد مسلکی شما چیه؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              در ۵ سال آینده خود را در چه حالتی می بینید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              تاریخچه ای کاری مربوط یا غیر مربوط خود را بنویسید.
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="6"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              در مراکز کاری گذشته، چطور شما و کار شما را توصیف
                              میکردند؟ (اگر سابقه کاری دارید)
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="3"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              در کار تیمی چگونه هستید و چطور خود با کار تیمی و
                              پیشبرد هدف مشخص به عنوان عضوی از گروه میتوانید کمک
                              کنید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="10"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              آیا شما انسان ریسک پذیری هستید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="3"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              وقتی فشار کار زیاد شود چطور با موقعیت هایی که زیر
                              استرس هستن برخورد میکنن؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              چقدر سریع با یک فناوری جدید سازگار میشوید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              درباره ای شرکت چه فکر میکنید و آیا اهداف شرکت نقشی
                              در علاقه مندی شما به کار در شرکت دارد؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              چطور شرکت لوی لالا می تواند بهتر شود؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="5"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              از شرکت چه توقع دارید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="3"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              چقدر به قوانین شرکت پایبند هستید و چرا؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="3"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              آیا به سلسله مراتب معتقد و متعهد هستید؟ چرا؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-50 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>

                            <label className="m-5 sm:scale-[68%] md:scale-100">
                              این شرکت بر پایه اعتبار، انضباط و اقتدار کار
                              میکند، شما در کار خود چگونه ای سه اصل را تطبیق می
                              کنید؟
                              <br />
                              <br />
                              <textarea
                                className="p-3 bg-slate-800 text-black"
                                name=""
                                cols="70"
                                rows="4"
                              ></textarea>
                            </label>
                          </div>
                        </div>

                        <div className="mb-12 pb-1 pt-1 text-center">
                          <input
                            className="mb-3 block w-40 rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                            type="submit"
                            value="ثبت درخواست"
                            style={{
                              background:
                                "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
