
import { useLocation } from 'react-router-dom';

import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function Register2() {
  const [enable,setEnable]=useState(false);


  const form = useRef();
  const navigate=useNavigate();
  const notify = () => toast("فورم شما موفقانه فرستاده شد");
  
  





  const location=useLocation()
  const newDataform=location.state;
  // console.log(newDataform)
  
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    riskTaker: '',
    companyThoughts: '',
    companyExpectations: '',
    hierarchy: '',
    companyValues: '',
  });
  const [RenderFormData, setRenderFormData] = useState({
    motivation: '',
    strengths: '',
    workHistory: '',
    jobDescription: '',
  });
  const AllFormData={...newDataform,...formData,...RenderFormData};
  // console.log(AllFormData);
  const handleNextLast = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
     ...prevFormData,
      [name]: value,
    }));
  };



  const addNewApplicant = async (e) => {
    const FormErrors={};
    if (!formData.riskTaker) {
      FormErrors.riskTaker = 'لطفاً این فیلد را پر کنید.';
    }
    if (!formData.companyThoughts) {
      FormErrors.companyThoughts = 'لطفاً این فیلد را پر کنید.';
    }
    if (!formData.companyExpectations) {
      FormErrors.companyExpectations = 'لطفاً این فیلد را پر کنید.';
    }   
    if (!formData.hierarchy) {
      FormErrors.hierarchy = 'لطفاً این فیلد را پر کنید.';
    }

   if (!formData.companyValues) {
      FormErrors.companyValues = 'لطفاً این فیلد را پر کنید.';
    } 
    if (Object.keys(FormErrors).length > 0) {
      setErrors(FormErrors);
      return;
    }

  
    
    
    
    e.preventDefault();
    
    
    let formField = new FormData();
    e.preventDefault();
    
    setEnable(true);
    formField.append("Name", AllFormData.name);
    
    formField.append("FName", AllFormData.fatherName);
    formField.append("Province", AllFormData.province);
    formField.append("Major", AllFormData.major);
    formField.append("Semister", AllFormData.semester);
    formField.append("Wtime", AllFormData.workTime);
    formField.append("Wfield", AllFormData.workField);
    formField.append("PN", AllFormData.phone);
    formField.append("Email", AllFormData.email);
    formField.append("Eng_r", AllFormData.englishReading);
    formField.append("Eng_w", AllFormData.englishWriting);
    formField.append("Eng_s", AllFormData.englishSpeaking);
    formField.append("Eng_l", AllFormData.englishListening);
    formField.append("ComputerSkills", AllFormData.computerSkills.toString());
    formField.append("riskTaker", AllFormData.riskTaker);
    formField.append("companyThoughts", AllFormData.companyThoughts);
    formField.append("companyExpectations", AllFormData.companyExpectations);
    formField.append("hierarchy", AllFormData.hierarchy);
    formField.append("companyValues", AllFormData.companyValues);
    formField.append("motivation", AllFormData.motivation);
    formField.append("strengths", AllFormData.strengths);
    formField.append("workHistory", AllFormData.workHistory);
    formField.append("jobDescription", AllFormData.jobDescription);
    notify();
    setTimeout(() => {
      window.scrollTo(0, 0);
      navigate("/");
  }, 4000);

  
    await axios({
      method: "post",
      url: "https://admin.luilala.com/api/createApplicant/",
      data: formField,
    }).then((response) => {
      emailjs
      .sendForm("service_lobt666", "template_wnv3o15", form.current, {
        publicKey: "O5y1SP2ZlaSMF6KjF",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          if (error.response) {
         console.log('error');
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        }
      );
   
      setTimeout(() => {
        window.scrollTo(0, 0);
        navigate("/");
    }, 3000);

      
      
    });


  };


  const [errors, setErrors] = useState({});
  // console.log(RenderFormData)
  const RenderPage1 = () => {

  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setRenderFormData((prevRenderFormData) => ({
        ...prevRenderFormData,
        [name]: value,
      }));
    };
  
    const handleNext = (e) => {
      e.preventDefault();
      const validationErrors = {};
  
      // Perform form validation
      if (!RenderFormData.motivation) {
        validationErrors.motivation = 'لطفاً این فیلد را پر کنید.';
      }
      if (!RenderFormData.strengths) {
        validationErrors.strengths = 'لطفاً این فیلد را پر کنید.';
      }
      if (!RenderFormData.workHistory) {
        validationErrors.workHistory = 'لطفاً این فیلد را پر کنید.';
      }
      if (!RenderFormData.jobDescription) {
        validationErrors.jobDescription = 'لطفاً این فیلد را پر کنید.';
      }
  
      // Update errors state
      setErrors(validationErrors);
  
      // Proceed to next page if there are no validation errors
      if (Object.keys(validationErrors).length === 0) {
        // Handle next page logic
        // console.log(RenderFormData);
       handleNextLast();
      }
    };
  
    return (
      <div dir="rtl" className="bg-[#00243f] min-h-screen">
        <div className="container p-8 mx-auto h-full">
        
          
        
          <h1 className="text-3xl my-12 bg-[#00243f] text-white">
            لطفاً این ورودی‌ها را پر کنید
          </h1>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
            {/* first field */}
            <div className="text-field input-1 inputText">
              <label className="label text-white">
                چرا می‌خواهید در این شرکت کار کنید و انگیزه شما چیست؟
              </label>
              <textarea
                name="motivation"
                value={RenderFormData.motivation}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                rows={5}
              />
              {errors.motivation && <div className="error text-red-500">{errors.motivation}</div>}
            </div>
  
            {/* second field */}
            <div className="text-field input-2 inputText">
              <label className="label text-white">در چه کار قوی هستید؟</label>
              <textarea
                name="strengths"
                value={RenderFormData.strengths}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                rows={5}
              />
              {errors.strengths && <div className="error text-red-500">{errors.strengths}</div>}
            </div>
  
            {/* third field */}
            <div className="text-field input-3 inputText">
              <label className="label text-white">تاریخچه‌ای کاری مربوط یا غیرمربوط خود را بنویسید.</label>
              <textarea
                name="workHistory"
                value={RenderFormData.workHistory}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                rows={5}
              />
              {errors.workHistory && <div className="error text-red-500">{errors.workHistory}</div>}
            </div>
  
            {/* fourth field */}
            <div className="text-field input-4 inputText">
              <label className="label text-white">
                در مراکز کاری گذشته، چطور شما و کار شما را توصیف می‌کردند؟ (اگر سابقه کاری دارید)
              </label>
              <textarea
                name="jobDescription"
                value={RenderFormData.jobDescription}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                rows={5}
              />
              {errors.jobDescription && <div className="error text-red-500">{errors.jobDescription}</div>}
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 flex justify-between mt-8 mr-4">
            <button onClick={handleNext} className="px-4 py-2 bg-white text-black rounded">
             ادامه
            </button>
            <button
              type="button"
              onClick={() => navigate('/register')}
              className="px-4 py-2 bg-white text-black rounded"
            >
               قبل
            </button>
          </div>
     
        </div>
      </div>
    );
  };
  // riskTaker: '',
  // companyThoughts: '',
  // companyExpectations: '',
  // hierarchy: '',
  // companyValues: '',
  const renderPage2 = () => {
    return (
      <div dir='rtl' className="  bg-[#001a3f]  ">
        <h1 className=" text-3xl p-8 mb-8 bg-[#001a3f] text-white">لطفاً این ورودی‌ها را پر کنید</h1>
        <div dir='rtl' className="container p-8 gap-8 text-white w-full min-h-screen bg-[#001a3f]">
          {/* fifth field */}
          <div className="grid grid-cols-1  justify-center items-center  md:grid-cols-2 gap-12 w-full">
            <div className="text-field input-5 inputText col-span-1">
              <label className="label">آیا شما انسان ریسک پذیری هستید؟</label>
              <textarea
                name="riskTaker"
                value={formData.riskTaker}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                cols={10}
                rows={5}
                required
              />
              {errors.riskTaker && <div className="error text-red-500">{errors.riskTaker}</div>}
            </div>
            {/* sixth field */}
            <div className="text-field input-6 inputText col-span-1">
              <label className="label">
                درباره‌ی شرکت چه فکر می‌کنید و آیا اهداف شرکت نقشی در علاقه‌مندی شما به کار در شرکت دارد؟
              </label>
              <textarea
                name="companyThoughts"
                value={formData.companyThoughts}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                cols={20}
                rows={5}
                required
              />
              {errors.companyThoughts && <div className="error text-red-500">{errors.companyThoughts}</div>}
            </div>
            {/* seventh field */}
            <div className="text-field input-7 inputText col-span-1">
              <label className="label">از شرکت چه توقعی دارید؟</label>
              <textarea
                name="companyExpectations"
                value={formData.companyExpectations}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                cols={20}
                rows={5}
                required
              />
              {errors.companyExpectations && <div className="error text-red-500">{errors.companyExpectations}</div>}
            </div>
            {/* eighth field */}
            <div className="text-field input-8 inputText col-span-1">
              <label className="label">آیا به سلسله‌مراتب معتقد و متعهد هستید؟ چرا؟</label>
              <textarea
                name="hierarchy"
                value={formData.hierarchy}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                cols={20}
                rows={5}
                required
              />
                {errors.hierarchy && <div className="error text-red-500">{errors.hierarchy}</div>}
            </div>
            {/* ninth field */}
            <div className="text-field justify-center relative md:left-[-50%]   input-9 inputText col-span-1">
              <label className="label">
                این شرکت بر پایه‌ی اعتبار، انضباط و اقتدار کار می‌کند، شما در کار خود چگونه این سه اصل را تطبیق می‌دهید؟
              </label>
              <textarea
                name="companyValues"
                value={formData.companyValues}
                onChange={handleInputChange}
                className="p-3 bg-slate-50 text-black resize-none w-full"
                cols={20}
                rows={5}
                required
              />
              {errors.companyValues && <div className="error text-red-500">{errors.companyValues}</div>}
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 flex justify-between mt-4 mr-4">
          <button type="submit" disabled={enable} onClick={addNewApplicant} className="px-4 py-2 bg-white text-black rounded">
              تائید 
            </button>

            
            <button type="button" onClick={handlePrevious} className="px-4 py-2 bg-white text-black rounded">
              قبل
            </button>
            
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  };
// first page


// second page


  return (
    
    <div>
      {currentPage === 1 && RenderPage1()}
      {currentPage === 2 && renderPage2()}
    </div>
    
  );
}

export default Register2;