import React from 'react'
import image from '../assets/research.jpg'

const About = () => {
  return (
    <div 
    
    style={{
      background:
        "linear-gradient(to right, #002f3f, #002a3f)",
    }} 
    className='font-vazir grid grid-cols-1 bg-[#001a3f] w-full'> 



    <div  dir='rtl'  style={{
      background:
        "#eee",
    }} className='about-style   my-0 sm:my-20   mx-0 sm:mx-6 md:mx-16 text-center  p-8 pt-24 sm:p-16 md:p-64  sm:rounded-2xl  '>
          <h1   className=' h1-about leading-tight text-[#001a3f] text-3xl '>شرکت فرهنگی رسانه ای لوی لالا</h1>
         
  <p class="company-description sm:p-0 p-8 mt-4">
  به شرکت فرهنگی رسانه‌ای لوی لالا خوش آمدید! جایی که ماموریت ما فراتر از مرزهای یک کسب و کار معمولی است. با تأسیس در قلب افغانستان، ما فانوس راهنمای نوآوری، تعهد و برتری هستیم. در شرکت فرهنگی رسانه‌ای لوی لالا هدف ما به پرورش استعداد غنی جوانان و ترویج فرهنگ رشد اجتماعی اختصاص یافته‌ است. 
  </p>

  <h2 class=" h2-about mt-4 text-[#001f3f] font-semibold text-2xlg">
    ارزش‌های اصلی ما
  </h2>

  <p class="company-description text-2xlg mt-4">
   <span className='font-bold'>
  اعتبار:
    
    </span> شرکت فرهنگی رسانه‌ای لوی لالا به کسب اعتماد از طریق شفافیت، صداقت، و پایبندی مداوم به وعده‌هایمان باور دارد.
  </p>

  <p class="company-description mt-4 text-[#001a3f] ">
   <span className='font-bold'>
انضباط:

   </span>
 شرکت فرهنگی رسانه‌ای لوی لالا بالاترین استانداردهای مسلکی و تعهد را حفظ می‌کند، اطمینان حاصل می‌کند که هر کاری با دقت فراوان انجام شود.
  </p>

  <p class="company-description mt-4">
    <span className='font-bold'>
  کیفیت: 

    </span>
  تعالی در خط مقدم کار ما قرار دارد. ما به دنبال فراتر رفتن از انتظارات با حفظ استانداردهای برتر در همه فعالیت‌هایی هستیم که انجام می‌دهیم.
  </p>

  <h2 class=" h2-about mt-4 text-[#001f3f] font-semibold text-2xlg">
    چشم‌انداز ما
  </h2>

  <p class=" company-description mt-8">چشم‌انداز شرکت فرهنگی رسانه‌ای لوی لالا انقلابی در چشم‌انداز کاری افغانستان است تا شرکتی را ایجاد کند که بازتابی از موفقیت و استانداردهای بالای کشورهای پیشرفته باشد. شرکت فرهنگی رسانه‌ای لوی لالا به بهتر شدن فرهنگ جامعه ما متعهد هست با:
  </p>

  <p class="company-description mt-4"
  >
    <span className='font-bold'>

    قدرتمند سازی جوانان: 
    </span>
    ایجاد فرصت‌های شغلی موثر برای جوانان در زمینه‌های مختلف مانند تعلیم، تکنالوژی معلوماتی، هنر، ورزش، ژورنالیسم، ترجمه، ادیت، تحقیق و مدیریت.
  </p>

  <p class="company-description mt-4">
    <span className='font-bold'>

    پایه‌گذاری تعلیمی: 
    </span>
    حمایت از پروسه تعلیم افراد از سطوح ابتدایی تا پوهنتون، بنایی محکم برای آینده‌ای روشن‌تر.

  </p>

  <p class="company-description mt-4"
  >
    <span className='font-bold'>

    پیشرفت مسلکی:
    </span>
     ارائه مسیرهای شغلی جذاب و مناسب به جوانان، توانمندسازی آنها برای یادگیری و رشد در زمینه‌های انتخابی شان.
  </p>

  <p class="company-description mt-4">
    <span className='font-bold'>

  شناسایی و پیشرفت استعدادها:
  </span>
   شناسایی و تربیت استعدادهای منحصر به فرد در جامعه، ارائه بسترهایی برای افراد تا بدرخشند و به طور مثبت به اجتماع کمک کنند.
  </p>

 

  <p class="company-description mt-4">
    
  شرکت فرهنگی رسانه‌ای لوی لالا, تنها یک شرکت نیست؛ یک میراث ماندگار است. از طریق ابتکارات مختلف, هدف شرکت ایجاد یک پروسه دراز مدت از فرصت‌ها, تقویت و پیشرفت است که استانداردهای زندگی و تفکر در افغانستان را ارتقا دهد. شرکت فرهنگی رسانه‌ای لوی لالا به ایجاد تغییر آهسته و پیوسته باور دارد.  
  </p>

            <h3>
    
            </h3>
          </div>
    
        </div>
 
  )
}

export default About;