import React from 'react'
import image1 from "../assets/cctv.jpg";
import image2 from "../assets/app.jpg";
import image3 from "../assets/research.jpg";
import image4 from "../assets/graphic.jpg";
import image5 from "../assets/trans.jpeg";
import { useNavigate } from 'react-router-dom';


const Service = () => {

  const navigate = useNavigate();
  return (

<div className='bg-slate-50 font-vazir'>

<div dir='rtl' className='  text-center text-black pt-8 mx-3 mb-16 '>
<h1 className='text-7xl  text-gray-600'>خدمات ما</h1>
<br />
<br />
<h3 className='text-3xl text-gray-500'>خدماتی که اکنون در شرکت فرهنگی رسانه‌ای لوی لالا در دسترس است.</h3>
</div>




    <div className='flex flex-wrap items-center justify-center gap-12  p-9'>  
<div class="relative flex flex-col  text-gray-700 bg-white shadow-md bg-clip-border overflow-hidden rounded-xl w-96 ">
  <div
    class="relative  h-70   overflow-hidden text-white shadow-lg bg-clip-border  bg-blue-gray-500 shadow-blue-gray-500/40">
    <img
      src={image1}
      className=''
      alt="card-image" />
  </div>
  <div dir='rtl' class="p-6">
    <h3 class="block mb-2 font-sans text-xl antialiased  leading-snug tracking-normal text-blue-gray-900 text-center font-bold ">
      CCTV
    </h3>
    <p class="block font-sans  text-lg font-light leading-relaxed text-inherit">
      <ul className='list-disc marker:text-red-400 p-2'>
        <li>نصب کامره ها</li>
        <li>فعال سازی و تهیه گزارش های تصویری از فعالیت های شرکت ها و ادارات</li>
      </ul>
    </p>
  </div>
  <div class="flex items-center justify-center p-6 pt-0">
    
  </div>
</div>  


<div class="relative flex flex-col mt-6 text-gray-700 bg-white overflow-hidden  shadow-md bg-clip-border rounded-xl w-96 ">
<div
    class="relative  h-70   overflow-hidden text-white shadow-lg bg-clip-border  bg-blue-gray-500 shadow-blue-gray-500/40">
    <img
      src={image2}
      className=''
      alt="card-image" />
  </div>
  <div dir='rtl' class="p-6">
    <h3 class="block mb-2 font-sans text-xl antialiased  leading-snug tracking-normal text-blue-gray-900 text-center font-bold ">
    Website and app development

    </h3>
    <p class="block font-sans text-lg font-light leading-relaxed text-inherit">
      <ul className='list-disc marker:text-red-400 p-2'>
        
        <li>ساخت وبسایت</li>
        <li>ساخت اپلیکیشن های موبایل</li>
        <li>هاستینگ سایت</li>
      </ul>
    </p>
  </div>
  <div class="flex items-center justify-center p-6 pt-0">
    
  </div>
</div>  





<div class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md  overflow-hidden  bg-clip-border overflow-hidden rounded-xl w-96 ">
<div
    class="relative  h-70   overflow-hidden text-white shadow-lg bg-clip-border  bg-blue-gray-500 shadow-blue-gray-500/40">
    <img
      src={image4}
      className=''
      alt="card-image" />
  </div>
  <div dir='rtl' class="p-6">
    <h3 class="block mb-2 font-sans text-xl antialiased  leading-snug tracking-normal text-blue-gray-900 text-center font-bold ">
    Graphics
    </h3>
    <p class="block font-sans text-lg font-light leading-relaxed text-inherit">
      <ul className='list-disc marker:text-red-400 p-2'>
        
        <li>تهیه و تولید انوع مختلف ویدیو برای تولید محتوا در فضای مجازی و تبلیغات</li>
        
        
      </ul>
      <br />
    </p>
  </div>
  <div class="flex items-center justify-center p-6 pt-0">
   
  </div>
</div>  



<div class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md overflow-hidden  bg-clip-border rounded-xl w-96 ">
<div
    class="relative  h-70   overflow-hidden text-white shadow-lg bg-clip-border  bg-blue-gray-500 shadow-blue-gray-500/40">
    <img
      src={image5}
      className=''
      alt="card-image" />
  </div>
  <div dir='rtl' class="p-6">
    <h3 class="block mb-2 font-sans text-xl antialiased  leading-snug tracking-normal text-blue-gray-900 text-center font-bold ">
    Translation Services
    </h3>
    <p class="block font-sans text-lg font-light leading-relaxed text-inherit">
      <ul className='list-disc marker:text-red-400 p-2'>
        
        <li>ارائه خدمات ترجمه از انگلیسی به فارسی و پشتو</li>
        
        
      </ul>
      <br />
      <br />
    </p>
  </div>
  <div class="flex items-center justify-center p-6 pt-0">
   
  </div>
</div>  




<div class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border  overflow-hidden  rounded-xl w-96 ">
<div
    class="relative  h-70   overflow-hidden text-white shadow-lg bg-clip-border  bg-blue-gray-500 shadow-blue-gray-500/40">
    <img
      src={image3}
      className=''
      alt="card-image" />
  </div>
  <div dir='rtl' class="p-6">
    <h3 class="block mb-2 font-sans text-xl antialiased  leading-snug tracking-normal text-blue-gray-900 text-center font-bold ">
    Research
    </h3>
    <p class="block font-sans text-lg font-light leading-relaxed text-inherit">
      <ul className='list-disc marker:text-red-400 p-2'>
        
        <li>تحقیقات میدانی و غیر میدانی در مورد موضوعات مختلف</li>
        <li>ارائه نتیجه تحقیق به زبان قابل فهم</li>
        
      </ul>
    </p>
  </div>
  <div class="flex items-center justify-center p-6 pt-0">
    
  </div>
</div>  
    </div>


    </div>
  )
}

export default Service