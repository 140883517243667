import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Svideo = ({VideoLink,VideoTitle}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout duration as needed
  }, []);

  return (
    <div className="font-vazir m-4 rounded-2xl text-gray-800 relative">
      <div className="card ml-8 w-[300px] sm:w-[420px] md:w-[500px] bg-gray-100 shadow-xl rounded-2xl overflow-hidden">
        <div className={`h-50 sm:h-60 ${loading ? 'bg-gray-300' : ''}`}>
          {loading ? (
            <div className="flex justify-center items-center h-[300px]">
              <ClipLoader color="#3a8aaf" loading={loading} size={60} />
            </div>
          ) : (
            <div className="aspect-video">
              <iframe
                src={`https://www.youtube.com/embed/${VideoLink}?controls=1`}
                title="YouTube video player"
                width="100%"
                height="92%"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              ></iframe>
            </div>
          )}
        </div>
        <div dir="rtl" className="card-body text-center h-auto ">
          <h2 className="card-title md:mt-12 font-semibold text-gray-700 text-3xl flex justify-start items-center h-auto">
            {VideoTitle}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Svideo;