import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../components/Pagination';
import { getResearchs } from '../services/getResearch';
import ClipLoader from 'react-spinners/ClipLoader';

const Newsletter = () => {
  const navigate = useNavigate();
  const [researchData, setResearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(new Array(researchData.length).fill(false));
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6); // Set records per page according to your design

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    getResearchs().then((data) => {
      if (mounted) {
        setResearchData(data);
        setLoading(false);
        setImageLoaded(new Array(data.length).fill(false));
      }
    });
    return () => (mounted = false);
  }, []);

  const handleImageLoad = (index) => {
    setImageLoaded((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  // Pagination Logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = researchData.slice(indexOfFirstRecord, indexOfLastRecord);

  const nPages = Math.ceil(researchData.length / recordsPerPage);

  return (
    <div dir="rtl" className="cards-reasearch h-auto flex flex-col items-center justify-center gap-0">
      <div className="titles-info">جدیدترین تحقیقات</div>
      {loading ? (
        <div className="flex justify-center items-center h-96">
          <ClipLoader color={"#000"} loading={loading} size={50} />
        </div>
      ) : (
        <>
          <div className="container-card gap-6 items-center grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-12 pt-12 w-full py-16 bg-gray-100">
            {currentRecords.map((item, index) => (
              <div key={index} className="card_1 shadow-lg card">
                {!imageLoaded[index] && (
                  <div className="flex justify-center items-center h-40">
                    <ClipLoader color={"#000"} loading={!imageLoaded[index]} size={30} />
                  </div>
                )}
                <img
                  className={`w-40 object-cover ${!imageLoaded[index] ? 'hidden' : 'block'}`}
                  src={item.rImage}
                  alt=""
                  onLoad={() => handleImageLoad(index)}
                />
                
                <div className="flex items-center text-sm mb-2">
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-gray-600 p-3">{item.created_at.substring(0,10)}</span>
            </div>

              
                <h1 className="text-clip font-bold px-3 pb-1 mb-2">{item.rTitle}</h1>
               

     <div style={{marginRight: 6 + 'px',marginLeft: 6 + 'px'}}
      dangerouslySetInnerHTML={{ __html:item.rBody.substring(0, 150) }}
    /><span style={{marginRight: 6 + 'px',marginLeft: 6 + 'px'}}>...</span>


                <Link
                  className="text-blue-500 px-3 pb-6"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/description', { state: item });
                    window.scrollTo(0, 0);
                  }}
                >
                  خواندن بیشتر
                </Link>
              </div>
            ))}
          </div>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </div>
  );
};

export default Newsletter;