import React from 'react'
import { useNavigate } from 'react-router-dom'

const Contact = () => {

  const navigate = useNavigate();
  return (
    <div 
    
    style={{
      background:
        "linear-gradient(to right, #002f3f, #002a3f)",
    }} 
    className='font-vazir grid grid-cols-1 bg-[#001a3f] w-full'> 



    <div dir='rtl'  style={{
      background:
        "linear-gradient(to right, #eee, #fff)",
    }} className='my-20 mx-16 text-center p-64  rounded-2xl opacity-95 '>
    
          <h1   className='text-[ #00153f] text-3xl '>تماس با ما</h1>
          <button dir='rtl' className='bg-teal-400 rounded-xl p-4 text-white m-5' style={{
                              background:
                                "linear-gradient(to right, #001a3f, #002f3f)",
                            }} onClick={() => navigate("/register")}>ثبت نام</button>
            <h3>
    
            </h3>
          </div>
    
        </div>
 
  )
}

export default Contact

       