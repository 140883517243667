import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getActivities } from '../services/getActivities';
import ClipLoader from 'react-spinners/ClipLoader';

const Activities = () => {
  const navigate = useNavigate();
  const [activitiesData, setActivitiesData] = useState([]);
  const [visibleActivities, setVisibleActivities] = useState(3);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    getActivities().then((data) => {
      if (mounted) {
        setActivitiesData(data);
        setLoading(false);
      }
    });
    return () => (mounted = false);
  }, []);

  const handleCardClick = (activity) => {
    navigate('/detailsActivity', { state: activity });
  };

  const handleShowMore = () => {
    setVisibleActivities(activitiesData.length);
  };

  const handleShowLess = () => {
    setVisibleActivities(3);
  };

  return (
    <div dir="rtl" className="p-10">
      {loading ? (
        // Render the spinner when loading is true
        <div className="flex justify-center items-center h-96">
          <ClipLoader color="#002a3f" size={50} />
        </div>
      ) : (
        // Render the rest of the content when loading is false
        <div className="space-y-4">
          {activitiesData.slice(0, visibleActivities).map((activity) => (
            <div
              key={activity.id}
              className="card-activity bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer flex flex-col md:flex-row"
              onClick={() => handleCardClick(activity)}
            >
              <img
                src={
                  Array.isArray(activity.images) && activity.images.length > 0
                    ? activity.images[0].images
                    : ''
                }
                alt={activity.activityTitle}
                className="w-full md:w-80 h-60 md:h-40 object-cover"
              />
              <div className="p-4 flex-1">
                <h3 className="text-2xl font-bold mb-2 text-[#001F3F]">
                  {activity.activityTitle}
                </h3>
                <p className="text-gray-600 mb-4">

                <div style={{marginRight: 6 + 'px',marginLeft: 6 + 'px'}}
      dangerouslySetInnerHTML={{ __html: activity.activityBody.substring(0,200) }}
    /><span style={{marginRight: 6 + 'px',marginLeft: 6 + 'px'}}>...</span>


                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center mt-6">
        {visibleActivities < activitiesData.length && (
          <button
            className="bg-[#002a3f] hover:bg-[#00243d] text-white font-bold py-2 px-4 rounded"
            onClick={handleShowMore}
          >
            نمایش بیشتر فعالیت‌ها
          </button>
        )}
        {visibleActivities === activitiesData.length && (
          <button
            className="bg-[#002a3f] hover:bg-[#00243d] text-white font-bold py-2 px-4 rounded"
            onClick={handleShowLess}
          >
            نمایش کمتر فعالیت‌ها
          </button>
        )}
      </div>
    </div>
  );
};

export default Activities;