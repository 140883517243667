import React, { useState } from "react";

import {  useLocation, useNavigate } from "react-router-dom";

import CheckboxInput from "./CheckboxInput ";


const Registerf = () => {
  const navigate=useNavigate()
  // const navigate = useNavigate();
  // const history = useHistory();
  const [newDataform, setnewDataform] = useState({
    name: '',
    fatherName: '',
    province: '',
    major: '',
    semester: 'First',
    workTime: 'صبح',
    workField: 'WEB Development',
    phone: '',
    email: '',
    englishReading: 'ضعیف',
    englishWriting: 'ضعیف',
    englishListening: 'ضعیف',
    englishSpeaking: 'ضعیف',
    computerSkills: ['افیس یاد ندارم'],
  });

  const [errors, setErrors] = useState({
    name: '',
    fatherName: '',
    province: '',
    major: '',
    semester: '',
    workTime: '',
    workField: '',
    phone: '',
    email: '',
    

    englishReading: '',
    englishWriting: '',
    englishSpeaking: '',
    englishListening: '',


  });
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const afghanPhonePattern = /^(\+93||0||93)?(7\d{8})$/;
    return afghanPhonePattern.test(phoneNumber);
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Validation logic for each field
    if (!newDataform.name) {
      newErrors.name = 'نام را وارد کنید';
      valid = false;
    } else {
      newErrors.name = '';
    }

    if (!newDataform.fatherName) {
      newErrors.fatherName = 'نام والد را وارد کنید';
      valid = false;
    } else {
      newErrors.fatherName = '';
    }

    if (!newDataform.province) {
      newErrors.province = 'ولایت را وارد کنید';
      valid = false;
    } else {
      newErrors.province = '';
    }

    if (!newDataform.major) {
      newErrors.major = 'رشته تحصیلی را وارد کنید';
      valid = false;
    } else {
      newErrors.major = '';
    }

    // if (!newDataform.semester) {
    //   newErrors.semester = 'فرایند تحصیلی را انتخاب کنید';
    //   valid = false;
    // } else {
    //   newErrors.semester = '';
    // }

    // if (!newDataform.workTime) {
    //   newErrors.workTime = 'وقت کاری را انتخاب کنید';
    //   valid = false;
    // } else {
    //   newErrors.workTime = '';
    // }

    // if (!newDataform.workField) {
    //   newErrors.workField = 'زمینه فعالیت را انتخاب کنید';
    //   valid = false;
    // } else {
    //   newErrors.workField = '';
    // }

    // if (!newDataform.phone) {
    //   newErrors.phone = 'شماره تلفن را وارد کنید';
    //   valid = false;
    // } else {
    //   newErrors.phone = '';
    // }
    
    if (!newDataform.phone) {
      newErrors.phone = 'شماره تلفن را وارد کنید';
      valid = false;
    } else if (!validatePhoneNumber(newDataform.phone)) {
      newErrors.phone = 'شماره تلفن نامعتبر است';
      valid = false;
    } else {
      newErrors.phone = '';
    }

    if (!validateEmail(newDataform.email)) {
      newErrors.email = 'ایمیل را وارد کنید';
      valid = false;

    } else {
      newErrors.email = '';
    }
    if (!validateEmail(newDataform.email)) {
      newErrors.email = 'ایمیل را وارد کنید';
      valid = false;
    } else {
      newErrors.email = '';
    }

    setErrors(newErrors);
    return valid;
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form submission logic
      
      // history.push('/register2', newDataform);
      navigate('/register2', { state: newDataform });
      

    } else {
      // alert('Form has errors. Cannot submit.');
      return false;
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setnewDataform(prevData => ({
        ...prevData,
        computerSkills: [...prevData.computerSkills, value],
      }));
    } else {
      setnewDataform(prevData => ({
        ...prevData,
        computerSkills: prevData.computerSkills.filter(skill => skill !== value),
      }));
    }
  };


  return (
  

      <section dir="rtl" className="section-form   w-fullmd:h-[130vh] text-[#999] p-8 container-form ">

      {/* <div className="flex justify-between items-center px-4 w-full h-20 border-b-2 border-gray-600">


      <h2 className="text-3xl font-bold">Register</h2>
      <button className="px-4 py-2 text-white rounded"> <ion-icon name="arrow-back-outline"></ion-icon>
  </button>

     </div> */}
<form dir="rtl" className="form-controle w-full grid grid-cols-1 sm:grid-cols-2 md:gap-4 rounded-lg shadow-sm">
  {/* first row */}
  <div className="w-full md:mb-0">
    <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2" htmlFor="grid-first-name">
      نام
    </label>
    <input
      onChange={(e) => setnewDataform({ ...newDataform, name: e.target.value })}
      className="appearance-none block w-full bg-gray-200 text-slate-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="grid-first-name"
      type="text"
      placeholder="نام"
      value={newDataform.name}
    />
    {errors.name && <p className="text-red-500">{errors.name}</p>}
  </div>

  <div className="w-full">
    <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2" htmlFor="grid-last-name">
      نام والد
    </label>
    <input
      onChange={(e) => setnewDataform({ ...newDataform, fatherName: e.target.value })}
      className="appearance-none block w-full bg-gray-200 text-slate-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="grid-last-name"
      type="text"
      placeholder="نام والد"
    />
    {errors.fatherName && <p className="text-red-500">{errors.fatherName}</p>}
  </div>

  {/* second row */}
  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
  <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2" htmlFor="grid-province">
    ولایت
  </label>
  <select
    onChange={(e) => setnewDataform({ ...newDataform, province: e.target.value })}
    className="appearance-none block w-full bg-gray-200 text-slate-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
    id="grid-province"
  >
    <option value="">انتخاب ولایت</option>
    <option value="کابل">کابل</option>
    <option value="قندهار">قندهار</option>
    <option value="هرات">هرات</option>
    <option value="بلخ">بلخ</option>
    <option value="ننگرهار">ننگرهار</option>
    <option value="کندز">کندز</option>
    <option value="تخار">تخار</option>
    <option value="فاریاب">فاریاب</option>
    <option value="غزنی">غزنی</option>
    <option value="بغلان">بغلان</option>
    <option value="بدخشان">بدخشان</option>
    <option value="پکتیا">پکتیا</option>
    <option value="خوست">خوست</option>
    <option value="هلمند">هلمند</option>
    <option value="نیمروز">نیمروز</option>
    <option value="بادغیس">بادغیس</option>
    <option value="غور">غور</option>
    <option value="دایکندی">دایکندی</option>
    <option value="ارزگان">ارزگان</option>
    <option value="پکتیکا">پکتیکا</option>
    <option value="سمنگان">سمنگان</option>
    <option value="جوزجان">جوزجان</option>
    <option value="سرپل">سرپل</option>
    <option value="پروان">پروان</option>
    <option value="لغمان">لغمان</option>
    <option value="کونر">کونر</option>
    <option value="نورستان">نورستان</option>
  </select>
  {errors.province && <p className="text-red-500">{errors.province}</p>}
</div>
  <div className="w-full px-3">
    <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2" htmlFor="grid-pn">
      رشته درسی
    </label>
    <input
      onChange={(e) => setnewDataform({ ...newDataform, major: e.target.value })}
      className="appearance-none block w-full bg-gray-200 text-slate-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="grid-pn"
      type="text"
      placeholder="رشته درسی"
    />
    {errors.major && <p className="text-red-500">{errors.major}</p>}
  </div>

  <div className="w-full px-3">
    <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2" htmlFor="semester">
      سمستر تحصیلی
    </label>
    <select
      onChange={(e) => setnewDataform({ ...newDataform, semester: e.target.value })}
      className="appearance-none block w-full bg-gray-200 text-slate-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="semester"
    >
      <option value="1">۱</option>
      <option value="2">۲</option>
      <option value="3">۳</option>
      <option value="4">۴</option>
      <option value="5">۵</option>
      <option value="6">۶</option>
      <option value="7">۷</option>
      <option value="8">۸</option>
    </select>
  </div>
    
          
    
  <div className="w-full md:w-[100%] px-3">
    <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2">
     وخت کاری
    </label>

    <select
      onChange={(e) => setnewDataform({ ...newDataform, workTime: e.target.value })}
      className="appearance-none block w-full bg-gray-200 text-gray-900 order border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      name="Wtime"
  
    >
      <option value="صبح">صبح</option>
      <option value="ظهر">ظهر</option>
    </select>
  </div>


  <div className="w-full md:w-[100%] px-3">

    <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2">
      در کدام بخش کار می کند
    </label>

    <select
      onChange={(e) => setnewDataform({ ...newDataform, workField: e.target.value })}
      className="appearance-none block w-full bg-gray-200 text-slate-900 order border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      name="Wfield"
      // value={Wfield} 
      // onChange={(e) => setWfield(e.target.value)}
    >
      <option value="Translation">Translation</option>
      <option value="Graphics">Graphics</option>
      <option value="Research">Research</option>
      <option value="Web App Design">Web App Design</option>  
      <option value="Admin">Admin</option>
    </select>

  </div>  
                     <div className="w-full  px-3">
                            <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2" htmlFor="grid-phone">
                              شماره تلفن
                              </label>
                              <input
                                onChange={(e) => setnewDataform({ ...newDataform, phone: e.target.value })}
                              className="appearance-none block w-full bg-gray-200 textxt-slate-900order border-gray-200 rounded py-3 px-4
                              leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-phone" type="text" placeholder="شماره تلفن"
                              />
                               {errors.phone&&
           <p className="text-red-500">{errors.phone} </p>
           } 
                              </div>

        {/* end of 3rd row */}


                 
                 
                    <div className="flex flex-wrap  mb-6">
                      <div className="w-full md:w-
                      1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text
                        -gray-700 text-xs font-bold mb-2" htmlFor
                        ="grid-email">
                          ایمیل
                          </label>
                          <input 
                            onChange={(e) => setnewDataform({ ...newDataform, email: e.target.value })}
                          className="appearance-none block w-full bg-gray-200 textxt-slate-900order border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border
                          -gray-500"
                          id="grid-email" type="email" placeholder="ایمیل"
                          />
                           {errors.email&&
           <p className="text-red-500">{errors.email} </p>
           } 
                          </div>
                          
                              </div>
                              </form>
{/* secondp row of section */}
                              <div className="part2  w-
                    full flex-col gap-4 flex p-12    justify-center items-center">
                                
                                <h1 className="text-2xl">
                              آشنایی به زبانهای خارجی، کامپیوتر و سایر دوره ها
                            </h1>
                             
                             <p class="mt-2 mb-8 text-2xl font-bold">انگلیسی</p>
<div dir="rtl" className="items-part2  flex  gap-2  border-gray-500 pb-8">
  <label className="mx-6 flex items-center text-3xg">
    <p>

  خواندن
    </p>
    <select 
      // value={Eng_r}
      // onChange={(e) => setEng_r(e.target.value)}
      onChange={(e) => setnewDataform({ ...newDataform, englishReading: e.target.value })}
      className="mr-2"
    >
      <option value="ضعیف">ضعیف</option>
      <option value="متوسط">متوسط</option>
      <option value="خوب">خوب</option>
      <option value="عالی">عالی</option>
    </select>
    {errors.englishReading&&
           <p className="text-red-500">{errors.englishReading} </p>
           } 
  </label>


 
    {/* second */}

    <label className="mx-6 flex items-center text-3xg">
    <p>

    نوشتن
    </p>
    <select 
      // value={Eng_r}
      // onChange={(e) => setEng_r(e.target.value)}
      onChange={(e) => setnewDataform({ ...newDataform, englishWriting: e.target.value })}
      className="mr-2"
    >
      <option value="ضعیف">ضعیف</option>
      <option value="متوسط">متوسط</option>
      <option value="خوب">خوب</option>
      <option value="عالی">عالی</option>
    </select>
    {errors.englishWriting&&
           <p className="text-red-500">{errors.englishWriting} </p>
           } 
  </label>


 
  <label className="mx-6 flex items-center text-3xg">
    <p>

    مکالمه
    </p>
    <select 
      // value={Eng_r}
      // onChange={(e) => setEng_r(e.target.value)}
      onChange={(e) => setnewDataform({ ...newDataform, englishSpeaking: e.target.value })}
      className="mr-2"
    >
      <option value="ضعیف">ضعیف</option>
      <option value="متوسط">متوسط</option>
      <option value="خوب">خوب</option>
      <option value="عالی">عالی</option>
    </select>
    {errors.englishSpeaking&&
           <p className="text-red-500">{errors.englishSpeaking} </p>
           } 
  </label>

  <label className="mx-6 flex items-center text-3xg">
    <p>

           شنیدن
    </p>
    <select 
      // value={Eng_r}
      // onChange={(e) => setEng_r(e.target.value)}
      onChange={(e) => setnewDataform({ ...newDataform, englishListening: e.target.value })}
      className="mr-2"
    >
      <option value="ضعیف">ضعیف</option>
      <option value="متوسط">متوسط</option>
      <option value="خوب">خوب</option>
      <option value="عالی">عالی</option>
    </select>
    {errors.englishListening&&
           <p className="text-red-500">{errors.englishListening} </p>
           } 
  </label>


</div>


<div className="text-center">
  <p className="mt-2 mb-8 text-2xl font-bold">آشنایی با کامپیوتر</p>
  <div className="bg-[#00243f] computer-options  p-4 rounded-lg shadow-md flex justify-center item-center  gap-2 flex-wrap">
        <CheckboxInput
          name="Windows"
          label="Windows"
          value="Windows"
          onChange={handleCheckboxChange}
        />

        <CheckboxInput
          name="word"
          label="MS Word"
          value="MS Word"
          onChange={handleCheckboxChange}
        />

        <CheckboxInput
          name="excel"
          label="MS Excel"
          value="MS Excel"
          onChange={handleCheckboxChange}
        />

        <CheckboxInput
          name="ppt"
          label="MS Power Point"
          value="MS Power Point"
          onChange={handleCheckboxChange}
        />

        <CheckboxInput
          name="internet"
          label="Internet"
          value="Internet"
          onChange={handleCheckboxChange}
        />
      </div>


  <button
 onClick={handleSubmit}
 className="text-slate-100 hover:bg-[#001f3f] font-vazir bg-[#00243f] hover:duration-500 rounded-2xl p-2 mt-12"
 >داده ها را ارسال کنید</button>
</div>



                              </div>
                 

                              {/* third part of the form */}
                              

            </section>
   
  );
};

export default Registerf;