import React from 'react';

const CheckboxInput = ({ name, label, value, onChange }) => {
    return (
      <label className="flex pr-2 items-center text-gray-600 font-medium mb-4">
        <input 
          onChange={onChange}
          name={name}
          type="checkbox" 
          className="ml-1 form-checkbox text-blue-500" 
          value={value}
        />
        <span>{label}</span>
      </label>
    );
  };
  
export default CheckboxInput;