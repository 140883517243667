import React from 'react';
import { Link } from 'react-router-dom';


const Hero = () => {
  return (
    <div
      dir="rtl"
      id="hero-image"
      className=" bg-white flex flex-col justify-center items-center mb-1 carousel relative w-full h-[85vh] block p-16"
    >
      <h1 className="  Hero-heading-h1 text-4xl md:text-5xl text-[#001F3F] font-bold">
      به شرکت فرهنگی رسانه‌ای لوی لالا خوش آمدید
      </h1>
      <p className="w-full md:w-[40vw] text-center text-[#777] mt-6">
        جایی که ماموریت ما فراتر از مرزهای یک کسب و کار معمولی است. با تأسیس در قلب افغانستان، ما فانوس راهنمای نوآوری، تعهد، و برتری هستیم. در شرکت فرهنگی رسانه‌ای لوی لالا به پرورش استعداد غنی جوانان و ترویج فرهنگ رشد مثبت اجتماعی اختصاص یافته‌ است.
      </p>
      <p className="mt-3 text-[#001F3F] font-bold">اگر میخواهید در این شرکت عضو شوید، کلیک کنید:</p>
      <Link
        to="/register"
        className="text-slate-100 hover:bg-[#000f3f] font-vazir bg-[#00243f] hover:duration-500 rounded-2xl p-2 mt-8 am:mt-4"
      >
        خودتان را ثبت کنید
      </Link>
    </div>
  );
};

export default Hero;
// #002245

// #000f3f
// #00153f
// #002f3f
// #002a3f
// #00243f
// #001f3f
// #001a3f


// <div id="slide1" className="carousel-item  w-full justify-center">
//     <img src="https://daisyui.com/images/stock/photo-1625726411847-8cbb60cc71e6.jpg" className="w-full" />
//     <figcaption class="absolute mx-auto   text-black bg-red-200 bg-opacity-50  shadow-3xl shadow-slate-800 bottom-6">
//       <h1 className='w-full md:text-3xl'>خدمات بچه ها</h1>
//       <br />
//       <p className='w-full md:text-2xl'>چطور میتوانیم یک وبسایت عالی بسازیم شسینمبتشسکمیبتشکمسنیبتشسکمینبتشمسکینتلشسیمکنبتشسمکینبت<br />شسیبنتشسمیبتشسمیبنتم</p>
//   </figcaption>

//     <div className="absolute flex  justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//       <a href="#slide4" className="btn btn-circle">❮</a> 
//       <a href="#slide2" className="btn btn-circle">❯</a>
//     </div>
//   </div> 
//   <div id="slide2" className="carousel-item relative  w-full justify-center">
//     <img src="https://daisyui.com/images/stock/photo-1609621838510-5ad474b7d25d.jpg" className="w-full" />
//     <figcaption class="absolute mx-auto   text-black bg-red-200 bg-opacity-50  shadow-3xl shadow-slate-800 bottom-6">
//       <h1 className='w-full md:text-3xl'>خدمات بچه ها</h1>
//       <br />
//       <p className='w-full md:text-2xl'>چطور میتوانیم یک وبسایت عالی بسازیم شسینمبتشسکمیبتشکمسنیبتشسکمینبتشمسکینتلشسیمکنبتشسمکینبت<br />شسیبنتشسمیبتشسمیبنتم</p>
//   </figcaption>
//     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//       <a href="#slide1" className="btn btn-circle">❮</a> 
//       <a href="#slide3" className="btn btn-circle">❯</a>
//     </div>
//   </div> 
//   <div id="slide3" className="carousel-item relative w-full justify-center">
//     <img src="https://daisyui.com/images/stock/photo-1414694762283-acccc27bca85.jpg" className="w-full" />
//     <figcaption class="absolute mx-auto   text-black bg-red-200 bg-opacity-50  shadow-3xl shadow-slate-800 bottom-6">
//       <h1 className='w-full md:text-3xl'>خدمات بچه ها</h1>
//       <br />
//       <p className='w-full md:text-2xl'>چطور میتوانیم یک وبسایت عالی بسازیم شسینمبتشسکمیبتشکمسنیبتشسکمینبتشمسکینتلشسیمکنبتشسمکینبت<br />شسیبنتشسمیبتشسمیبنتم</p>
//   </figcaption>
//     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//       <a href="#slide2" className="btn btn-circle">❮</a> 
//       <a href="#slide4" className="btn btn-circle">❯</a>
//     </div>
//   </div> 
//   <div id="slide4" className="carousel-item  w-full justify-center">
//     <img src="https://daisyui.com/images/stock/photo-1665553365602-b2fb8e5d1707.jpg" className="w-full" />
//     <figcaption class="absolute mx-auto   text-black bg-red-200 bg-opacity-50  shadow-3xl shadow-slate-800 bottom-6">
//       <h1 className='w-full md:text-3xl'>خدمات بچه ها</h1>
//       <br />
//       <p className='w-full md:text-2xl'>چطور میتوانیم یک وبسایت عالی بسازیم شسینمبتشسکمیبتشکمسنیبتشسکمینبتشمسکینتلشسیمکنبتشسمکینبت<br />شسیبنتشسمیبتشسمیبنتم</p>
//   </figcaption>
//     <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//       <a href="#slide3" className="btn btn-circle">❮</a> 
//       <a href="#slide1" className="btn btn-circle">❯</a>
//     </div>
//   </div>
