import React from 'react';
import logo from "../assets/logo.png";
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div
    dir="rtl"
    className="flex items-center flex-col mx-auto py-12 px-4 text-white"
    style={{
      background: "linear-gradient(to right, #002a3f, #00243f)",
    }}
  >
    <div className="contents-footer">
      <ul className="items-footer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 md:gap-4 sm:justify-between justify-center gap-12 md:items-start md:items-start w-[90vw]">
        <li className="items-center">
          <a href="/">
            <div className="mx-16 inline-block w-16 shrink-0 rounded-full ring ring-primary ring-offset-base-30 ring-offset-2">
              <img className="rounded-full" src={logo} />
            </div>
          </a>
          <a href="#">شرکت فرهنگی رسانه ای لوی لالا</a>
          <a>قندهار، افغانستان</a>
        </li>
        <li>
          <h1>خدمات</h1>
          <a href="#">تحقیقات</a>
          <a href="#">سیستم های دوربین مدار بسته</a>
          <a href="#">طراحی ویب</a>
          <a href="#">ویرایش ویدیو</a>
          <a href="#">ویرایش صدا</a>
          <a href="#">اطلاعات فرهنگی</a>
        </li>
        <li>
          <h1>محصولات</h1>
          <a href="#">سیستم های سی سی تی وی</a>
          <a href="#">سیستم های حاضری چهره و اثر انگشت</a>
          <a href="#">محتوای متنی و تصویری</a>
         
        </li>
     
        <li>
          <h1>درباره ما
</h1>
        <p className='text-[#999] inline pl-12 sm:p-0 text-justify	'>شرکت فرهنگی رسانه ای لوی لالا؛ جایی که ماموریت ما فراتر از مرزهای یک کسب و کار معمولی است. با تأسیس در قلب افغانستان، ما فانوس راهنمای نوآوری، تعهد، و برتری هستیم. در شرکت فرهنگی رسانه‌ای لوی لالا به پرورش استعداد غنی جوانان و ترویج فرهنگ رشد مثبت اجتماعی اختصاص یافته‌ است 
       
        <Link
      className="text-blue-500 px-3 text-right pb-6"
      onClick={(e) => {
        e.preventDefault();
       
        navigate('/about');
        window.scrollTo(0, 0);
      }}
    >
      
      درباره ما بیشتر بدانید...
    </Link>
        {/* <Link className='inline decoration-underline'  to="/description ">درباره ما زیا بخواند...</Link> */}
        </p>
        </li>

        <li  className="contact-footer mr-8 order-2 md:order-3">
          <h1>مخاطب</h1>
          <a href="mailto:luilalachannel@gmail.com">luilalachannel@gmail.com</a>
          <div className="flex gap-8">
            <a href="https://www.facebook.com/profile.php?id=61551014648604&mibextid=ZbWKwL">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
            <a href="https://x.com/Luilalachannel?t=7lJ0ZiBbBcGqPbpshLDiOg&s=35">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
            <a href="https://www.tiktok.com/@luilalachannel?_t=8lcl4srmD0z&_r=1">
              <ion-icon name="logo-tiktok"></ion-icon>
            </a>
          </div>
        </li>
      </ul> 
    </div>
    <div
      dir="ltr"
      className="copyright border-t-2 w-[90vw] pt-8 text-gray-400 border-gray-700 mt-14 text-center"
    >
      © 2023 - 2024 Luilala Ltd All Rights Reserved. Privacy Policy | Terms and
      Conditions
    </div>
  </div>
  );
};

export default Footer;
