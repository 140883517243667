import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
   
    

    const goToNextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1);
        window.scrollTo(0, 0);
    }
    return (
        <nav>
            <ul className="join flex flex-justify mx-auto justify-center mt-4 mb-7 text-white">
                <li className="page-item">
                    <a className="join-item btn btn-outline  hover:bg-[#001a3f] hover:bg-opacity-50 hover:text-white" 
                        onClick={goToPrevPage} 
                        >
                        
                        صفحه قبل
                    </a>
                </li>
                {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? "bg-cyan-200" : ""} `} >

                        <a onClick={() => {setCurrentPage(pgNumber);window.scrollTo(0, 0);}}  
                            className="join-item btn btn-outline  visited:bg-teal-100 hover:bg-[#001a3f] hover:bg-opacity-50 hover:text-white" 
                            >
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="join-item btn btn-outline  hover:bg-[#001a3f] hover:bg-opacity-50 hover:text-white" 
                        onClick={goToNextPage}
                        >
                        
                        صفحه بعد
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination