import React from "react";
import Svideo from "../components/Svideo";
import { getVideos } from "../services/getVideos";
import { useEffect, useState } from "react";
import Pagination from '../components/Pagination';

const Vpage = () => {
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(4);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    let mounted = true;

    getVideos().then((data) => {
      if (mounted) {
        setVideos(data);
        setFilteredVideos(data);
        setLoading(false);
      }
    });
    return () => (mounted = false);
  }, []);

  const currentRecords = filteredVideos.slice(indexOfFirstRecord, indexOfLastRecord);

  const nPages = Math.ceil(filteredVideos.length / recordsPerPage)

  const handleFilter = (event) => {
    const value = event.currentTarget.getAttribute("data-value");
    setFilteredVideos(videos.filter(d=>d.VideoCtg.toLowerCase().includes(value)));
  };

  return (
    <div className="font-vazir">
      <div className="grid grid-flow-col bg-[#002f3f] mx-auto w-4/5 md:w-3/5 rounded-3xl my-3 p-5 shadow-2xl">
        <ul dir="rtl" className="flex flex-auto justify-center items-center gap-5 text-white video">
          <li className="sm:px-3">
            <button
              className="hover:bg-[#669bb5] rounded-lg px-2 hover:duration-500 py-2"
              onClick={handleFilter}
              data-value=""
            >
              همه ویدیو ها
            </button>
          </li>
          <li className="sm:px-3">
            <button
              className="hover:bg-[#669bb5] rounded-lg px-2 hover:duration-500 py-2"
              onClick={handleFilter}
              data-value="story"
            >
              داستان ها
            </button>
          </li>
          <li className="sm:px-3">
            <button
              className="hover:bg-[#669bb5] rounded-lg px-2 hover:duration-500 py-2"
              onClick={handleFilter}
              data-value="informational"
            >
              معلوماتی
            </button>
          </li>
          <li className="sm:px-3">
            <button
              className="hover:bg-[#669bb5] rounded-lg px-2 hover:duration-500 py-2"
              onClick={handleFilter}
              data-value="sports"
            >
              ورزشی
            </button>
          </li>
          <li className="sm:px-3">
            <button
              className="hover:bg-[#669bb5] rounded-lg px-2 hover:duration-500 py-2"
              onClick={handleFilter}
              data-value="activity"
            >
              فعالیت ها
            </button>
          </li>
        </ul>
      </div>

      <div dir="rtl" className="flex  w-[90vw] flex-wrap justify-center items-center gap-5 text-white video">
        {currentRecords.map((vid) => (
          <div key={vid.id}>
            <Svideo
              VideoTitle={vid.VideoTitle}
              VideoLink={vid.VideoLinkId}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center my-5">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Vpage;